import { createAsyncThunk, createEntityAdapter, createSelector, createSlice, nanoid } from "@reduxjs/toolkit";

import { reportsModelingService } from "../../services/reports-modeling-service.js";
import { PERMISSIONS_TREE_VARIANT_LEVEL, REPORT_LEVELS, REPORT_TYPES, REPORT_VARIANT_TYPES } from "../../utils/sodReports.js";
import { BUSY_TYPES } from "../../utils/busy.js";
import { REPORT_MODELING_DELTA_STATUSES, getModelingRolesForCalc, getModelingGroupRolesForCalc, initializeNewRoles, parseModelingUsersToCalcForm, getDeletedDefaultValuesForTcodes, REPORT_MODELING_CONFLICT_LEVELS_USER } from "../../utils/reports-modeling-utils.js";
import { checkRoleExists, getModelingRole } from "../../utils/user-level-modeling.js";
import { getSelectedRoleReducers } from "./selectedRoleReducers.js";
import { getDefaultPermissionsValuesForTransactions, getTransactionFromRoleMenu, getPfcgTreeForTcodes, getTcodesMapCountFromMenu, getDeletedTcodesFromMenu, getTransactionsByIds } from "../../utils/role-menu.js";
import { getRolePermissionsMap, recalcRoleTreeTrafficLightStatus } from "../../utils/reports-modeling-utils.js";
import { findItemInTree, TreeWalker } from "../../utils/tree-table.js";

const modelingUsers = createEntityAdapter();
const modelingRoles = createEntityAdapter();

const initialResults = {
    canFetchResults: false,
    resultsOpen: false,
    reportLevel: REPORT_LEVELS.OPERATIONS,
    reportType: REPORT_TYPES.FINAL,
    conflictLevel: REPORT_MODELING_CONFLICT_LEVELS_USER.USER,
    rows: [],
    columns: [],
    total: 0,
    selectedIds: [],
    searchString: "",
    pageNumber: 1,
    limitNumber: 10,
    sortData: [],
    filterData: [],
};

const initialState = {
    users: [],
    systems: [],
    extendedSystems: [],
    matrixHeaders: [],
    risks: [],
    riskLevels: [],
    groups: [],
    parametersOpen: true,

    ...initialResults,

    busy: false,
    busyType: null,
    error: null,
    validationErrors: null,

    variantCreated: false,
    variants: [],
    currentVariant: null,
    standardParameters: {
        users: [],
        systems: [],
        matrixHeaders: [],
        risks: [],
        riskLevels: [],
        extendedSystems: []
    },

    modeling: {
        open: false,
        users: modelingUsers.getInitialState(),
        roles: modelingRoles.getInitialState(),
        selectedUserId: "",
        selectedRoleId: "",
    }
};

export const fetchModelingUsers = createAsyncThunk(
    "reportsModeling/userLevel/fetchModelingUsers",
    async (_, { getState, rejectWithValue }) => {
        const state = getState();
        const currentVariantName = selectCurrentVariantName(state);

        if (state.reportsModeling.userLevel.users.length === 0){
            return rejectWithValue()
        }

        const users = await reportsModelingService.getModelingUsers(
            state.reportsModeling.userLevel.users,
            state.reportsModeling.userLevel.extendedSystems.map(item => item.id),
            PERMISSIONS_TREE_VARIANT_LEVEL.USERS,
            currentVariantName
        )

        return { users };
    }
);


export const removeMenuItemFromTree = createAsyncThunk(
    "reportsModeling/userLevel/removeMenuItem",
    async ({ menuItemPath, id }, { getState, dispatch }) => {

        const state = getState();

        const selectedRole = selectModelingRoleById(state, id);

        if (!selectedRole) {
            return
        }

        const removedMenuItem = findItemInTree(menuItemPath, selectedRole.menu)

        const tcodesMapItem = getTcodesMapCountFromMenu([removedMenuItem])

        dispatch(removeRoleMenuNode({
            id: selectedRole.id,
            activeNodePath: menuItemPath
        }))

        const tcodesMapMenu = getTcodesMapCountFromMenu(selectedRole.menu)

        const { deletedTcodes, remainedTcodes } = getDeletedTcodesFromMenu(tcodesMapItem, tcodesMapMenu)

        if (deletedTcodes.size === 0) {
            return
        }

        try {
            const { defaultValuesForDelete, orgLevelsForDelete } = getDeletedDefaultValuesForTcodes(deletedTcodes, remainedTcodes, selectedRole.tcodeToValue)

            dispatch(substractPfcgTreeFromRole({
                id,
                defaultValuesForDelete,
                orgLevelsForDelete
            }))


        } catch (error) {
            console.log(error)
        }

        //fk01 bupa rlt actvt 1 2 3
        //f110 bupa rlt actvt 1 2 4 5
        //se38 bupa rlt actvt 8 9
        //FK02 bupa rlt actvt 2
        //summ 1 2 3 4 5 8 9 

        // if (removedMenuItem && !removedMenuItem.isFolder) {
        //     const tcode = removedMenuItem.reportName

        //     const defaultValues = await getDefaultPermissionsValuesForTransactions(selectedRole.systemId, [tcode]);

        //     dispatch(substractPfcgTreeFromRole({
        //         id,
        //         defaultValues
        //     }))
        // }

    }
);

export const fetchReportColumns = createAsyncThunk(
    "reportsModeling/userLevel/fetchReportColumns",
    async (_, { getState }) => {
        const state = getState();
        const columns = await reportsModelingService.getUserLevelModelingColumns(
            state.reportsModeling.userLevel.reportLevel,
            state.reportsModeling.userLevel.reportType
        );
        return columns;
    }
);

export const fetchReportResults = createAsyncThunk(
    "reportsModeling/userLevel/fetchReportResults",
    async ({ params }, { getState }) => {
        const state = getState();

        if (!state.reportsModeling.userLevel.canFetchResults) {
            return;
        }

        const conflictLevel = state.reportsModeling.userLevel.conflictLevel;

        const newParams = {
            ...params,
            "report_level": state.reportsModeling.userLevel.reportLevel,
            "report_type": state.reportsModeling.userLevel.reportType,
            "conflict_level": conflictLevel,
        };

        const response = await reportsModelingService.getUserLevelReportResults(newParams);
        return response;
    }
);

const createRoleForModeling = (role) => ({
    id: nanoid(),
    role: role.role,
    systemId: role.systemId,
    initialized: false,
    isComplex: role.isComplex,
    menu: [],
    tree: [],
    orgLevels: [],
    children: [],
    delta: [],
    orgLevelsDelta: [],
    manual: role.manual || false,
    profile: role.profile || role.role
})

export const calcReport = createAsyncThunk(
    "reportsModeling/userLevel//calcReport",
    async (_, { getState, rejectWithValue }) => {
        const state = getState();
        const currentVariantName = selectCurrentVariantName(state);

        const calcUsers = Object.values(state.reportsModeling.userLevel.modeling.users.entities)
        const changedUsers = calcUsers.filter(user => user.roles.some(role => role.status))

        const calcRoles = Object.values(state.reportsModeling.userLevel.modeling.roles.entities)


        const changedRoleToUserMap = {}

        const addedRoleIdSet = new Set()

        calcUsers.forEach(user => user.roles.forEach(role => {
            if (role.changed) {
                changedRoleToUserMap[role.id] = user.employee
            }

            if (role.status === REPORT_MODELING_DELTA_STATUSES.ADDED) {
                addedRoleIdSet.add(role.id)
            }
        }))

        const systemsForCalc = state.reportsModeling.userLevel.extendedSystems.filter(item => !item.isGroup).map(item => item.id)
        const changedUsersForCalc = parseModelingUsersToCalcForm(changedUsers);

        const rolesToFetch = calcRoles.filter(role => changedRoleToUserMap[role.id] && !role.initialized);

        const prefetchedRoleMap = await getRolePermissionsMap(
            rolesToFetch, currentVariantName, PERMISSIONS_TREE_VARIANT_LEVEL.USERS, changedRoleToUserMap
        );

        const changedRolesForCalc = await getModelingRolesForCalc(calcRoles, prefetchedRoleMap, changedRoleToUserMap);
        const changedGroupRolesForCalc = await getModelingGroupRolesForCalc(calcRoles, prefetchedRoleMap, changedRoleToUserMap);

        await initializeNewRoles(calcRoles, addedRoleIdSet)

        const form = {
            usernames: state.reportsModeling.userLevel.users,
            systems: systemsForCalc,
            matrix_headers: state.reportsModeling.userLevel.matrixHeaders,
            risks: state.reportsModeling.userLevel.risks,
            risk_levels: state.reportsModeling.userLevel.riskLevels,
            groups: [],
            changed_users: changedUsersForCalc,
            changed_roles: changedRolesForCalc,
            changed_group_roles: changedGroupRolesForCalc
        };

        try {
            await reportsModelingService.calcUserLevelModelingReport(form);
        } catch (error) {
            return rejectWithValue(error)
        }
    }
);


export const calcCompareReport = createAsyncThunk(
    "reportsModeling/userLevel//calcCompareReport",
    async (_, { getState, rejectWithValue }) => {
        const state = getState();
        const currentVariantName = selectCurrentVariantName(state);

        const calcUsers = Object.values(state.reportsModeling.userLevel.modeling.users.entities)
        const changedUsers = calcUsers.filter(user => user.roles.some(role => role.status))

        const calcRoles = Object.values(state.reportsModeling.userLevel.modeling.roles.entities)


        const changedRoleToUserMap = {}

        const addedRoleIdSet = new Set()

        calcUsers.forEach(user => user.roles.forEach(role => {
            if (role.changed){
                changedRoleToUserMap[role.id] = user.employee
            }

            if (role.status === REPORT_MODELING_DELTA_STATUSES.ADDED){
                addedRoleIdSet.add(role.id)
            }
        }))

        const systemsForCalc = state.reportsModeling.userLevel.extendedSystems.filter(item => !item.isGroup).map(item => item.id)
        const changedUsersForCalc = parseModelingUsersToCalcForm(changedUsers);

        const rolesToFetch = calcRoles.filter(role => changedRoleToUserMap[role.id] && !role.initialized);

        const prefetchedRoleMap = await getRolePermissionsMap(
            rolesToFetch, currentVariantName, PERMISSIONS_TREE_VARIANT_LEVEL.USERS, changedRoleToUserMap
        );

        const changedRolesForCalc = await getModelingRolesForCalc(calcRoles, prefetchedRoleMap, changedRoleToUserMap);
        const changedGroupRolesForCalc = await getModelingGroupRolesForCalc(calcRoles, prefetchedRoleMap, changedRoleToUserMap);

        const form = {
            usernames: state.reportsModeling.userLevel.users,
            systems: systemsForCalc,
            matrix_headers: state.reportsModeling.userLevel.matrixHeaders,
            risks: state.reportsModeling.userLevel.risks,
            risk_levels: state.reportsModeling.userLevel.riskLevels,
            groups: [],
            changed_users: changedUsersForCalc,
            changed_roles: changedRolesForCalc,
            changed_group_roles: changedGroupRolesForCalc
        };

        try {
            await reportsModelingService.calcUserLevelModelingCompareReport(form);
        } catch (error) {
            return rejectWithValue(error)
        }
    }
);

export const saveVariant = createAsyncThunk(
    "reportsModeling/userLevel/saveVariant",
    async (variantName, { getState, rejectWithValue }) => {
        const state = getState();

        const isVariantNew = !state.reportsModeling.userLevel.variants.some(
            variant => variant.variantName === variantName
        );

        const currentVariantName = selectCurrentVariantName(state);

        const newParams = {
            "variant_name": variantName,
            "variant_type": REPORT_VARIANT_TYPES.USERS_MODELING,
        };

        const systemsForCalc = state.reportsModeling.userLevel.extendedSystems.filter(item => !item.isGroup).map(item => item.id)

        const variantData = {
            "usernames": state.reportsModeling.userLevel.users,
            "systems": systemsForCalc,
            "matrix_headers": state.reportsModeling.userLevel.matrixHeaders,
            "risks": state.reportsModeling.userLevel.risks,
            "risk_levels": state.reportsModeling.userLevel.riskLevels,
            "groups": []
        }

        const form = {
            variant_name: variantName,
            variant_data: variantData,
            variant_type: REPORT_VARIANT_TYPES.USERS_MODELING
        }

        const modelingRolesMap = state.reportsModeling.userLevel.modeling.roles.entities;
        const modelingUsers = Object.values(state.reportsModeling.userLevel.modeling.users.entities);

        const rolesData = []

        modelingUsers.forEach(user => {
            user.roles.forEach(role => {
                const modelingRole = modelingRolesMap[role.id]

                const parsedRole = reportsModelingService.parseRoleToTreeVariant(modelingRole)

                rolesData.push({
                    ...parsedRole,
                    operation: role.status,
                    user: user.employee,
                    is_changed: role.changed
                })
            })
        })

        const requestData = {
            variant_name: variantName,
            old_variant_name: currentVariantName,
            level: PERMISSIONS_TREE_VARIANT_LEVEL.USERS,
            variants: rolesData
        }

        try {
            const [savedVariant] = await Promise.all([
                reportsModelingService.saveVariant(form, newParams, isVariantNew),
                reportsModelingService.savePermissionsTreeVariant(requestData)
            ]);

            return savedVariant;
        } catch (error) {
            return rejectWithValue(error)
        }
    }
);

export const deleteVariant = createAsyncThunk(
    "reportsModeling/userLevel/deleteVariant",
    async (variantName, { rejectWithValue }) => {
        const newParams = {
            "variant_name": variantName,
            "variant_type": REPORT_VARIANT_TYPES.USERS_MODELING,
        };

        try {
            return await reportsModelingService.deleteVariant(newParams);
        } catch (error) {
            return rejectWithValue(error)
        }
    }
);

export const setFavoritesForVariant = createAsyncThunk(
    "reportsModeling/userLevel/setFavoritesForVariant",
    async ({ variantName, inFavorites }, { rejectWithValue }) => {
        const form = {
            variant_name: variantName,
            variant_type: REPORT_VARIANT_TYPES.USERS_MODELING,
            in_favorites: inFavorites
        };

        try {
            return await reportsModelingService.setFavoritesForVariant(form);
        } catch (error) {
            return rejectWithValue(error)
        }
    }
);

export const fetchVariantsByUser = createAsyncThunk(
    "reportsModeling/userLevel/fetchVariantsByUser",
    async (searchString) => {
        const params = {
            variant_type: REPORT_VARIANT_TYPES.USERS_MODELING,
        };

        if (searchString) {
            params.search = searchString;
        }

        const response = await reportsModelingService.getVariantsByUser(params);

        return response;
    }
);

export const exportReport = createAsyncThunk(
    "reportsModeling/userLevel/exportReport",
    async (_, { getState, rejectWithValue }) => {
        const state = getState();

        const params = {
            "report_level": state.reportsModeling.userLevel.reportLevel,
            "report_type": state.reportsModeling.userLevel.reportType,
        };

        try {
            await reportsModelingService.exportUserLevelReport(params);
        } catch (error) {
            rejectWithValue(error);
        }
    }
);

export const setReportLevelWithColumns = (reportLevel) => (dispatch) => {
    dispatch(setReportLevel(reportLevel));
    dispatch(fetchReportColumns());
};

export const setReportTypeWithColumns = (reportType) => (dispatch) => {
    dispatch(setReportType(reportType));
    dispatch(fetchReportColumns());
};

export const saveReportColumns = createAsyncThunk(
    "reportsModeling/userLevel/saveReportColumns",
    async ({ columns, reportLevel, reportType }) => {
        await reportsModelingService.saveUserLevelModelingColumns(columns, reportLevel, reportType);
        return { columns, reportLevel, reportType };
    }
);

export const setModelingSelectedUserById = (id) => (dispatch, getState) => {
    const state = getState();
    const user = selectModelingUserById(state, id);

    dispatch(setModelingSelectedUserId(user?.id ?? ""));
};

export const setModelingSelectedUserByName = (name = "") => (dispatch, getState) => {
    const state = getState();

    const lowerName = name.toLowerCase();

    const user = selectModelingUsers(state).find(user => (
        user.employee.toLowerCase() === lowerName
    ));

    dispatch(setModelingSelectedUserId(user?.id ?? ""));
};

export const addModelingSelectedUserRole = createAsyncThunk(
    "reportsModeling/userLevel/addModelingSelectedUserRole",
    async (role, { getState, rejectWithValue }) => {
        const state = getState();
        const user = selectModelingSelectedUser(state);
        const currentVariantName = selectCurrentVariantName(state);

        const userRoles = user.roles

        if (!userRoles) {
            return;
        }

        const userHasRole = userRoles.some(userRole => (
            userRole.role === role.role &&
            userRole.systemId === role.systemId
        ));

        if (userHasRole) {
            return rejectWithValue({
                useIntl: true,
                errorMessageId: "user-level-modeling.user-has-role-error"
            });
        }

        const roles = selectModelingRoles(state);
        const roleToAdd = await getModelingRole(role.role, role.systemId, roles, currentVariantName, PERMISSIONS_TREE_VARIANT_LEVEL.USERS, role["manual"]);

        if (!roleToAdd) {
            return rejectWithValue({
                useIntl: true,
                errorMessageId: "user-level-modeling.role-doesnt-exist-error"
            });
        }

        return { ...roleToAdd };
    }
);

export const createModelingSelectedUserRole = createAsyncThunk(
    "reportsModeling/userLevel/createModelingSelectedUserRole",
    async ({ role, description, isComplex }, { getState, rejectWithValue }) => {
        const state = getState();
        const user = selectModelingSelectedUser(state);

        if (!user) {
            return;
        }

        const roles = selectModelingRoles(state);
        const roleExists = await checkRoleExists(role, user.systemId, roles);

        if (roleExists) {
            return rejectWithValue({
                useIntl: true,
                errorMessageId: "user-level-modeling.role-exists-error"
            });
        } else {
            try {
                await reportsModelingService.createNewRole(role, description, user.systemId, isComplex)
            } catch (error) {
                return rejectWithValue({
                    useIntl: true,
                    errorMessageId: "user-level-modeling.role-creation-error"
                });
            }
        }

        return {
            role,
            description,
            manual: true,
            systemId: user.systemId,
            isComplex,
            profile: null,
            status: REPORT_MODELING_DELTA_STATUSES.ADDED
        };
    }
);

export const setModelingSelectedRole = createAsyncThunk(
    "reportsModeling/userLevel/setModelingSelectedRole",
    async (role, { rejectWithValue, getState }) => {
        if (role.initialized) {
            return role;
        }

        const state = getState();
        const currentVariantName = selectCurrentVariantName(state);
        const selectedUser = selectModelingSelectedUser(state)

        try {

            const { menu, tree, orgLevels, childrens = [], tcodeToValue = {} } = await reportsModelingService.getRolePermissionsVariant(
                role.role, role.systemId, currentVariantName, PERMISSIONS_TREE_VARIANT_LEVEL.USERS, selectedUser.employee
            )

            if(tree && orgLevels){
                recalcRoleTreeTrafficLightStatus(tree, orgLevels)
             }

            return {
                ...role,
                delta: [],
                orgLevelsDelta: [],
                tree: tree ?? [],
                orgLevels: orgLevels ?? {},
                menu: menu,
                children: childrens,
                initialized: true,
                tcodeToValue: tcodeToValue
            };
        } catch (error) {
            rejectWithValue(error);
        }
    }
);

export const setModelingSelectedRoleById = (id) => (dispatch, getState) => {
    const state = getState();

    const role = selectModelingRoleById(state, id)

    dispatch(setModelingSelectedRole(role));
};

export const setModelingSelectedRoleByName = (name = "") => (dispatch, getState) => {
    const state = getState();

    const lowerName = name.toLowerCase();

    const role = selectModelingSelectedUserRoles(state).find(role => (
        role.role.toLowerCase() === lowerName
    ));

    dispatch(setModelingSelectedRole(role));
};

export const addDefaultPermissionsForMenuUpdate = createAsyncThunk(
    "reportsModeling/userLevel/addDefaultPermissionsForMenuUpdate",
    async ({ id, transactionIds }, { getState, dispatch }) => {
        const state = getState();
        const role = selectModelingRoleById(state, id);

        if (!role || !transactionIds?.length) {
            return;
        }

        const newPermissionsTree = await getPfcgTreeForTcodes(role.systemId, transactionIds)

        const defaultValues = await getDefaultPermissionsValuesForTransactions(role.systemId, transactionIds);


        // const newPermissionsTree = await getNewPermissionsTree(
        //     role.systemId,
        //     new Set(defaultValues.map(value => value.permission)),
        //     role.tree
        // );

        // console.log(newPermissionsTree)
        // console.log(pfcgBranch)

        dispatch(addModelingRoleTreeItems({
            id,
            items: newPermissionsTree,
            defaultValues,
            tcodes: transactionIds,
            isStandart: true
        }));
    }
);

export const addRoleMenuNodeWithDefaultValues = ({ id, activeNodePath, tcodes }) => async (dispatch, getState) => {

    let state = getState();
    let selectedRole = selectModelingRoleById(state, id);

    if (!selectedRole) {
        return
    }

    const transactions = await getTransactionsByIds(selectedRole.systemId, tcodes);

    dispatch(addRoleMenuNode({
        id,
        activeNodePath,
        tcodes,
        transactions
    }));

     state = getState();
     selectedRole = selectModelingRoleById(state, id);

    const tcodesMapMenu = getTcodesMapCountFromMenu(selectedRole.menu)

    const tcodesForDefaultPermissions = tcodes.filter(tcode => tcodesMapMenu[tcode] && tcodesMapMenu[tcode] > 0)

    if (tcodesForDefaultPermissions.length > 0) {
        dispatch(addDefaultPermissionsForMenuUpdate({
            id,
            transactionIds: tcodesForDefaultPermissions
        }));
    }
};

export const copyRoleMenuWithDefaultValues = ({ id, menuToCopy }) => (dispatch) => {
    if (!menuToCopy?.length) {
        return;
    }

    dispatch(copyRoleMenu({
        id,
        menuToCopy
    }));

    const transactionIds = getTransactionFromRoleMenu(menuToCopy[0].children);

    dispatch(addDefaultPermissionsForMenuUpdate({
        id,
        transactionIds
    }));
};

export const setVariantWithFetchModeling = createAsyncThunk(
    "reportsModeling/userLevel/setVariantWithFetchModeling",
    async (variant, { dispatch }) => {
        dispatch(setCurrentVariant(variant));

        if (!variant) {
            dispatch(clearVariant());
            //return;
        }

        await dispatch(fetchModelingUsers())
    }
);

export const setConflictLevelWithColumns = (conflictLevel) => (dispatch) => {
    dispatch(setConflictLevel(conflictLevel));
    dispatch(fetchReportColumns());
};

const selectedRoleReducers = getSelectedRoleReducers(
    (state) => state.modeling.roles,
    modelingRoles,
    (state, role) => {
        const users = Object.values(state.modeling.users.entities)

        users.forEach(user => {
            const changedRole = user.roles.find(item => item.id === role.id)

            if (changedRole) {
                changedRole.changed = true
            }
        })
    },
    true
);

const userLevelSlice = createSlice({
    name: "reportsModeling/userLevel",
    initialState: initialState,
    reducers: {
        setUsers(state, action) {
            state.users = action.payload;
        },

        setSystems(state, action) {
            state.systems = action.payload;
        },

        setSystemsExtended(state, action) {
            state.extendedSystems = action.payload
        },

        setMatrixHeaders(state, action) {
            state.matrixHeaders = action.payload;
        },

        setRisks(state, action) {
            state.risks = action.payload;
        },

        setRiskLevels(state, action) {
            state.riskLevels = action.payload;
        },

        setParametersOpen(state, action) {
            state.parametersOpen = action.payload
        },

        setResultsOpen(state, action) {
            state.resultsOpen = action.payload;
        },

        setReportLevel(state, action) {
            state.reportLevel = action.payload;
            state.pageNumber = 1;
            state.searchString = "";
            state.filterData = [];
            state.sortData = [];
        },

        setReportType(state, action) {
            state.reportType = action.payload;
            state.pageNumber = 1;
            state.searchString = "";
            state.filterData = [];
            state.sortData = [];
        },

        setConflictLevel(state, action) {
            state.pageNumber = 1;
            state.searchString = "";
            state.filterData = [];
            state.sortData = [];
            state.conflictLevel = action.payload;
        },

        setError(state, action) {
            state.error = action.payload;
        },

        setSelectedIds(state, action) {
            state.selectedIds = action.payload;
        },

        setSearchString(state, action) {
            if (state.searchString !== action.payload) {
                state.pageNumber = 1;
                state.searchString = action.payload;
            }
        },

        setPageNumber(state, action) {
            state.pageNumber = action.payload;
        },

        setLimitNumber(state, action) {
            state.pageNumber = 1;
            state.limitNumber = action.payload;
        },

        setSortData(state, action) {
            state.pageNumber = 1;
            state.sortData = action.payload;
        },

        setFilterData(state, action) {
            state.pageNumber = 1;
            state.filterData = action.payload;
        },

        setCurrentVariant(state, action) {
            // if (!state.currentVariant) {
            //     state.standardParameters.users = state.users;
            //     state.standardParameters.systems = state.systems;
            //     state.standardParameters.matrixHeaders = state.matrixHeaders;
            //     state.standardParameters.risks = state.risks;
            //     state.standardParameters.riskLevels = state.riskLevels;
            //     state.standardParameters.extendedSystems = state.extendedSystems
            // }

            state.standardParameters.users = state.users;
            state.standardParameters.systems = state.systems;
            state.standardParameters.matrixHeaders = state.matrixHeaders;
            state.standardParameters.risks = state.risks;
            state.standardParameters.riskLevels = state.riskLevels;
            state.standardParameters.extendedSystems = state.extendedSystems

            const variant = action.payload;

            const systemsCurrent = variant ? variant.variantData.systems : state.standardParameters.extendedSystems.filter(item => !item.isGroup).map(item => item.id)
            const groupsCurrent = variant ? variant.variantData.groups : state.standardParameters.extendedSystems.filter(item => item.isGroup).map(item => item.id)

            const systemPart = systemsCurrent.map(system => ({
                id: system,
                isGroup: false
            }))

            const groupPart = groupsCurrent.map(group => ({
                id: group,
                isGroup: true
            }))

            state.currentVariant = variant;

            state.users = variant
                ? variant.variantData.usernames
                : state.standardParameters.users;

            state.systems = variant
                ? variant.variantData.systems
                : state.standardParameters.systems;

            state.extendedSystems = [...systemPart, ...groupPart]

            state.matrixHeaders = variant
                ? variant.variantData.matrixHeaders
                : state.standardParameters.matrixHeaders;

            state.risks = variant
                ? variant.variantData.risks
                : state.standardParameters.risks;

            state.riskLevels = variant
                ? variant.variantData.riskLevels
                : state.standardParameters.riskLevels;
        },

        clearFilters(state) {
            state.selectedIds = [];
            state.searchString = "";
            state.pageNumber = 1;
            state.sortData = [];
            state.filterData = [];
        },

        clearVariant(state) {
            state.modeling.users = modelingUsers.getInitialState()
            state.modeling.roles = modelingRoles.getInitialState()
            state.modeling.selectedUserId = ""
            state.modeling.selectedRoleId = ""
        },

        setModelingSelectedUserId(state, action) {
            if (state.modeling.selectedUserId === action.payload?.id) {
                return;
            }

            state.modeling.selectedUserId = action.payload;
            state.modeling.selectedRoleId = "";
        },


        deleteModelingSelectedUserRole(state, action) {
            const user = state.modeling.users.entities[
                state.modeling.selectedUserId
            ];
            const roleId = action.payload;

            if (!user || !roleId) {
                return;
            }

            //const modelingRole = state.modeling.roles.entities[roleId];
            const userRole = user.roles.find(role => role.id == roleId)

            //const statusModelingRole = modelingRole.status;
            const userRoleStatus = userRole.status;

            const userChanges = {}

            switch (userRoleStatus) {
                case REPORT_MODELING_DELTA_STATUSES.ADDED:
                    userChanges.roles = user.roles.filter(role => role.id !== roleId);
                    break;

                case REPORT_MODELING_DELTA_STATUSES.DELETED:
                    userRole.status = ""
                    userChanges.roles = user.roles
                    break;

                default:
                    userRole.status = REPORT_MODELING_DELTA_STATUSES.DELETED
                    userChanges.roles = user.roles
                    break;
            }

            state.modeling.selectedRoleId = null

            modelingUsers.updateOne(state.modeling.users, {
                id: user.id,
                changes: userChanges
            });
        },

        setModelingOpen(state, action) {
            state.modeling.open = action.payload;
        },

        initModeling(state) {
            state.parametersOpen = true;
            state.modeling = initialState.modeling;

            for (const resultsKey in initialResults) {
                state[resultsKey] = initialResults[resultsKey];
            }
        },

        expandItemTree(state,action){
            const roleId = state.modeling.selectedRoleId;
            const role = state.modeling.roles.entities[roleId];
            const item = findItemInTree(action.payload.path, role.tree)
            item.open = !item.open;
            const treeWalker = new TreeWalker(item.children);
            treeWalker.walk(
                node => {
                    node.open = item.open;
                },
                () => {
                    return {
                        keyField: "",
                        keyValue: "",
                        childField: "children"
                    }
                },
                () => {

                }
            )
        },

        ...selectedRoleReducers
    },
    extraReducers: {
        [fetchReportResults.pending]: (state) => {
            state.busy = true;
            state.busyType = BUSY_TYPES.LOAD;
            state.rows = [];
        },

        [fetchReportResults.rejected]: (state, action) => {
            const error = action.payload;

            state.busy = false;
            state.error = error;

            if (error && error.code === "validation") {
                state.validationErrors = error.errors;
            }
        },

        [fetchReportResults.fulfilled]: (state, action) => {
            state.busy = false;
            state.error = null;
            state.validationErrors = null;

            if (action.payload) {
                state.resultsOpen = true;
                state.rows = action.payload.rows;
                state.total = action.payload.total;
            }
        },

        [calcReport.pending]: (state) => {
            state.busy = true;
            state.busyType = BUSY_TYPES.LOAD;
            state.error = null;
            state.validationErrors = null;
        },

        [calcReport.rejected]: (state, action) => {
            const error = action.payload || action.error;

            state.busy = false;
            state.error = error;

            if (error && error.code === "validation") {
                state.validationErrors = error.errors;
            }
        },

        [calcReport.fulfilled]: (state) => {
            state.selectedIds = [];
            state.searchString = "";
            state.pageNumber = 1;
            state.sortData = [];
            state.filterData = [];
            state.canFetchResults = true;
        },



        [calcCompareReport.pending]: (state) => {
            state.busy = true;
            state.busyType = BUSY_TYPES.LOAD;
            state.error = null;
            state.validationErrors = null;
        },

        [calcCompareReport.rejected]: (state, action) => {
            const error = action.payload || action.error;

            state.busy = false;
            state.error = error;

            if (error && error.code === "validation") {
                state.validationErrors = error.errors;
            }
        },

        [calcCompareReport.fulfilled]: (state) => {
            state.selectedIds = [];
            state.searchString = "";
            state.pageNumber = 1;
            state.sortData = [];
            state.filterData = [];
            state.canFetchResults = true;
        },

        [fetchReportColumns.pending]: (state) => {
            state.busy = true;
            state.busyType = BUSY_TYPES.LOAD;
        },

        [fetchReportColumns.fulfilled]: (state, action) => {
            state.busy = false;
            state.columns = action.payload?.filter(column => column.active);
        },

        [fetchReportColumns.rejected]: (state, action) => {
            state.busy = false;
            state.error = action.error;
        },


        [saveVariant.pending]: (state) => {
            state.variantCreated = false;
            state.busy = true;
            state.busyType = BUSY_TYPES.SAVE;
        },

        [saveVariant.fulfilled]: (state, action) => {
            state.busy = false;
            state.validationErrors = [];
            state.variantCreated = true;

            const savedVariant = action.payload;
            const index = state.variants.findIndex(variant => variant.variantName === savedVariant.variantName);

            if (index === -1) {
                state.variants.push(savedVariant);
            } else {
                state.variants[index] = savedVariant;
            }

            if (state.currentVariant?.variantName === savedVariant.variantName) {
                state.currentVariant = savedVariant;
            }
        },

        [saveVariant.rejected]: (state, action) => {
            const error = action.payload;

            state.busy = false;
            state.error = error;

            if (error && error.code === "validation") {
                state.validationErrors = error.errors;
            }
        },


        [deleteVariant.pending]: (state) => {
            state.busy = true;
            state.busyType = BUSY_TYPES.SAVE;
        },

        [deleteVariant.fulfilled]: (state, action) => {
            const variantName = action.meta.arg;

            state.busy = false;
            state.variants = state.variants.filter(variant => variant.variantName !== variantName);

            if (state.currentVariant?.variantName === variantName) {
                state.currentVariant = null;
                state.users = [];
                state.systems = [];
                state.extendedSystems = []
                state.matrixHeaders = [];
                state.risks = [];
                state.riskLevels = [];
            }
        },

        [deleteVariant.rejected]: (state, action) => {
            state.busy = false;
            state.error = action.payload;
        },

        [setFavoritesForVariant.pending]: (state) => {
            state.busy = true;
            state.busyType = BUSY_TYPES.SAVE;
        },

        [setFavoritesForVariant.fulfilled]: (state, action) => {
            const { variantName, inFavorites } = action.meta.arg;

            state.busy = false;

            const variant = state.variants.find(variant => variant.variantName === variantName);

            if (variant) {
                variant.inFavorites = inFavorites;
            }

            if (state.currentVariant?.variantName === variantName) {
                state.currentVariant.inFavorites = inFavorites;
            }
        },

        [setFavoritesForVariant.rejected]: (state, action) => {
            state.busy = false;
            state.error = action.payload;
        },

        [fetchVariantsByUser.fulfilled]: (state, action) => {
            state.variants = action.payload;
        },

        [fetchVariantsByUser.rejected]: (state, action) => {
            state.error = action.error;
        },

        [exportReport.rejected]: (state, action) => {
            state.error = action.error;
        },

        [saveReportColumns.fulfilled]: (state, action) => {
            const { columns, reportLevel, reportType } = action.payload;

            if (state.reportLevel === reportLevel &&
                state.reportType === reportType) {
                state.columns = columns.filter(column => column.active);
            }
        },

        [fetchModelingUsers.pending]: (state) => {
            state.busy = true;
            state.busyType = BUSY_TYPES.LOAD;
        },

        [fetchModelingUsers.fulfilled]: (state, action) => {
            const { users } = action.payload;

            const roleMap = {}

            for (const user of users) {

                let userWasChanged = false


                //generate id for roles and add them to single place
                user.roles.forEach(role => {

                    if (roleMap[role.role]) {
                        role.id = roleMap[role.role].id

                        return
                    }

                    const roleExtended = createRoleForModeling(role)

                    roleMap[role.role] = roleExtended
                    role.id = roleExtended.id

                    if (role.status) { userWasChanged = true }
                })

                //paste generated role id to user roles


                if (userWasChanged) {
                    user.status = REPORT_MODELING_DELTA_STATUSES.CHANGED;
                }
            }

            modelingUsers.setAll(state.modeling.users, users);
            modelingRoles.setAll(state.modeling.roles, Object.values(roleMap));

            state.busy = false;
            state.parametersOpen = false;
            state.modeling.open = true;
            state.modeling.selectedRoleId = "";
            state.modeling.selectedUserId = "";

            for (const resultsKey in initialResults) {
                state[resultsKey] = initialResults[resultsKey];
            }
        },

        [fetchModelingUsers.rejected]: (state, action) => {
            state.error = action.error;
            state.busy = false;
        },

        [addModelingSelectedUserRole.pending]: (state) => {
            state.busy = true;
            state.busyType = BUSY_TYPES.LOAD;
        },

        [addModelingSelectedUserRole.fulfilled]: (state, action) => {
            const role = action.payload;
            const user = state.modeling.users.entities[
                state.modeling.selectedUserId
            ];

            role.status = REPORT_MODELING_DELTA_STATUSES.ADDED

            state.busy = false;

            const modelingRole = createRoleForModeling(role)
            const modelingRolesCurrent = Object.values(state.modeling.roles.entities)

            role.changed = false

            const modelingRoleIndex = modelingRolesCurrent.findIndex(role => role.role === modelingRole.role && role.systemId === modelingRole.systemId)

            if (modelingRoleIndex === -1) {
                modelingRoles.addOne(state.modeling.roles, modelingRole);
                role.id = modelingRole.id
            } else {
                const currentModelingRole = modelingRolesCurrent[modelingRoleIndex]
                role.id = currentModelingRole.id
                role.changed = true
            }

            modelingUsers.updateOne(state.modeling.users, {
                id: user.id,
                changes: {
                    roles: [...user.roles, role]
                }
            });
        },

        [addModelingSelectedUserRole.rejected]: (state) => {
            state.busy = false;
        },

        [createModelingSelectedUserRole.fulfilled]: (state, action) => {
            const role = action.payload;

            const user = state.modeling.users.entities[
                state.modeling.selectedUserId
            ];

            state.busy = false;

            const modelingRolesCurrent = Object.values(state.modeling.roles.entities)
            const modelingRole = createRoleForModeling(role)

            role.id = modelingRole.id
            role.changed = false

            modelingUsers.updateOne(state.modeling.users, {
                id: user.id,
                changes: {
                    roles: [...user.roles, role]
                }
            });

            const modelingRoleIndex = modelingRolesCurrent.findIndex(role => role.role === modelingRole.role && role.systemId === modelingRole.systemId)

            if (modelingRoleIndex === -1) {
                modelingRoles.addOne(state.modeling.roles, modelingRole);
            }
        },

        [setModelingSelectedRole.pending]: (state) => {
            state.busy = true;
            state.busyType = BUSY_TYPES.LOAD;
        },

        [setModelingSelectedRole.fulfilled]: (state, action) => {
            const role = action.payload;

            if (!role) {
                state.modeling.selectedRoleId = "";
            } else if (role.id in state.modeling.roles.entities) {
                state.modeling.selectedRoleId = role.id;
                modelingRoles.setOne(state.modeling.roles, role);
            }

            state.busy = false;
        },

        [setModelingSelectedRole.rejected]: (state, action) => {
            state.error = action.error;
            state.busy = false;
        },
    }
});

export const {
    setUsers, setSystems, setSystemsExtended, setMatrixHeaders, setRisks,
    setRiskLevels, setParametersOpen, setResultsOpen,
    setReportLevel, setReportType, setError, setSelectedIds,
    setSearchString, setPageNumber, setLimitNumber, setSortData,
    setFilterData, setCurrentVariant, clearFilters,
    setModelingSelectedUserId,
    deleteModelingSelectedUserRole,
    setModelingOpen, setModelingRoleTreeField, addModelingRoleTreeItems,
    setModelingRoleOrgLevelValues, openRoleMenuNode, addRoleMenuFolder,
    addRoleMenuNode, removeRoleMenuNode, copyRoleMenu,
    addGroupRoleChild, removeGroupRoleChild, initModeling, removeModelingRoleTreeItem, substractPfcgTreeFromRole, clearVariant,
    setConflictLevel, expandItemTree
} = userLevelSlice.actions;

export const {
    selectIds: selectModelingUserIds,
    selectAll: selectModelingUsers,
    selectById: selectModelingUserById
} = modelingUsers.getSelectors(state => state.reportsModeling.userLevel.modeling.users);

export const {
    selectIds: selectModelingRoleIds,
    selectAll: selectModelingRoles,
    selectById: selectModelingRoleById
} = modelingRoles.getSelectors(state => state.reportsModeling.userLevel.modeling.roles);

export const selectModelingSelectedUserId = state => state.reportsModeling.userLevel.modeling.selectedUserId;
export const selectModelingSelectedUser = state => {
    const selectedUserId = selectModelingSelectedUserId(state);
    return selectModelingUserById(state, selectedUserId)
};
export const selectModelingSelectedUserSystemId = createSelector(selectModelingSelectedUser, (user) => user?.systemId);

export const selectModelingUsersBySearch = createSelector(
    selectModelingUsers,
    (_, searchString = "") => searchString.toLowerCase(),
    (users, searchString) => users.filter(({ employee }) => employee.toLowerCase().includes(searchString))
);

export const selectModelingSelectedRoleId = state => state.reportsModeling.userLevel.modeling.selectedRoleId;
export const selectModelingSelectedRole = state => {
    const selectedRoleId = selectModelingSelectedRoleId(state);
    return selectModelingRoleById(state, selectedRoleId)
};

export const selectModelingOpen = state => state.reportsModeling.userLevel.modeling.open;

export const selectModelingSelectedUserRoles = createSelector(
    selectModelingSelectedUser,
    selectModelingRoles,
    (_, searchString = "") => searchString.toLowerCase(),
    (user, roles, searchString) => {
        if (!user) return;

        return roles
            .filter(role => (
                user.roles.includes(role.id) &&
                role.role.toLowerCase().includes(searchString)
            ))
            .map(role => ({
                ...role,
                status: user.roleStatuses[role.id] || role.status
            }));
    }
);

export const selectModelingNewRoles = createSelector(
    selectModelingRoles,
    (_, systemId) => systemId,
    (_, __, searchString = "") => searchString.toLowerCase(),
    (roles, systemId, searchString = "") => roles.filter(role => (
        role.status === REPORT_MODELING_DELTA_STATUSES.ADDED &&
        role.systemId === systemId &&
        role.role.toLowerCase().includes(searchString)
    ))
);

export const selectCurrentVariantName = state => state.reportsModeling.userLevel.currentVariant?.variantName;

export default userLevelSlice.reducer;
